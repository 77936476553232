import React from "react";
import PageLayout from "../components/page-layout";
import { Accordion, AccordionItem, AccordionPanel } from "../elements/accordion";

const Giveaway = () => {
  const tableHeaderClasses = "py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-gray-400";
  const tableDataClasses = "py-4 px-6 text-sm font-medium whitespace-nowrap text-white";
  const tableRowClasses = "border-b bg-gray-800 border-gray-700";
  return (
    <PageLayout>
      <div className="sm:grid sm:grid-rows-2 flex flex-col sm:flex-none bg-gradient-to-r from-zinc-500 via-sky-900 to-zinc-500 pt-40 sm:gap-32">
        <div className="container mx-auto sm:flex sm:flex-col items-center">
          <div className="w-full sm:w-2/3 lg:flex justify-center items-center flex-col">
            <h1 className="text-7xl pb-5 font-black text-yellow-400 text-center">
              Giveaway
              <span className="text-amber-400"> Weekly Results </span>
            </h1>
          </div>
          <div className="justify-center text-center pb-52 sm:w-4/5 text-xl sm:text-2xl">
            <p className="pb-5 text-yellow-400">
              Holders of the Asgardian Bulls Club NFT Collection are passively entered into the weekly giveaways
            </p>
            <p className="text-white">
              Giveaways consist initially from the returns on investment of mint proceeds into DeFi, Node protocol, and
              other sustainable investments. As such, giveaway amounts are variable but are expected to grow in number
              and distribution over time.
            </p>
          </div>
        </div>
        <div className="mx-4 mb-4">
          <div className="bg-white container rounded-lg sm:mx-auto flex flex-col items-center py-16 sm:py-24">
            <h1 className="text-4xl sm:text-7xl pb-5 font-black">Giveaway Results</h1>
            <div className="w-full rounded-lg lg:w-3/4 text-center">
              <Accordion>
                <AccordionItem className="bg-slate-400" toggle="panel-1">
                  Giveaway #1: 04/15/2022
                </AccordionItem>
                <AccordionPanel id="panel-1">
                  <div className="flex flex-col">
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block py-2 min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow-md sm:rounded-lg">
                          <table className="sm:min-w-full">
                            <thead className="bg-gray-700">
                              <tr>
                                <th scope="col" className={tableHeaderClasses}>
                                  ID
                                </th>
                                <th scope="col" className={tableHeaderClasses}>
                                  Image
                                </th>
                                <th scope="col" className={tableHeaderClasses}>
                                  TX
                                </th>
                                <th scope="col" className={tableHeaderClasses}>
                                  Amount
                                </th>
                                <th scope="col" className={tableHeaderClasses}>
                                  Details
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className={tableRowClasses}>
                                <td className={tableDataClasses}>TBD</td>
                                <td className={tableDataClasses}>TBD</td>
                                <td className={tableDataClasses}>TBD</td>
                                <td className={tableDataClasses}>TBD</td>
                                <td className={tableDataClasses}>TBD</td>
                              </tr>
                              <tr className={tableRowClasses}>
                                <td className={tableDataClasses}>TBD</td>
                                <td className={tableDataClasses}>TBD</td>
                                <td className={tableDataClasses}>TBD</td>
                                <td className={tableDataClasses}>TBD</td>
                                <td className={tableDataClasses}>TBD</td>
                              </tr>
                              <tr className={tableRowClasses}>
                                <td className={tableDataClasses}>TBD</td>
                                <td className={tableDataClasses}>TBD</td>
                                <td className={tableDataClasses}>TBD</td>
                                <td className={tableDataClasses}>TBD</td>
                                <td className={tableDataClasses}>TBD</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionPanel>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Giveaway;
